<template>
  <div>
    <input type="text" style="display:none" />
    <input type="password" style="display:none" />
    <Navbar :hideSearch="true" />
    <div class="app-body my-5 py-5" style="min-height:60vh">
      <div class="columns">
        <div class="column is-6">
          <p class="text-subtitle bold">Change Password</p>
          <hr />
          <b-field label="Current Password">
            <b-input
              v-model="password.current"
              autocomplete="new-password"
              type="password"
            ></b-input>
          </b-field>
          <b-field label="New Password">
            <b-input
              v-model="password.new"
              autocomplete="new-password"
              type="password"
            ></b-input>
          </b-field>
          <b-field label="Confirm New Password">
            <b-input
              v-model="password.confirm"
              autocomplete="new-password"
              type="password"
            ></b-input>
          </b-field>
          <b-button
            :loading="isLoading"
            type="is-success"
            class="mt-2"
            @click="changePassword"
            >Change Password</b-button
          >
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/app/Navbar";
import Footer from "@/components/app/Footer";
import api from "@/services/dataService";
import Notification from "@/services/notificationService";

export default {
  name: "MyIssuances",
  components: {
    Navbar,
    Footer
  },
  data() {
    return {
      issuances: undefined,
      isLoading: false,
      password: {
        current: "",
        new: "",
        confirm: ""
      }
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    }
  },
  methods: {
    async changePassword() {
      this.isLoading = true;
      if (this.password.new == this.password.confirm) {
        await api
          .post("/user/details/change-password", {
            newPassword: this.password.new,
            oldPassword: this.password.current
          })
          .then(() => {
            Notification.success("Password has been successfully changed");
          })
          .catch(err => {
            Notification.error(err.message[0]);
          });
      } else {
        Notification.error("New password and Confirmed Password do not match");
      }
      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
